@font-face {
  font-family: 'Nexa Bold';
  src: local('Nexa Bold'), url(./fonts/Nexa-Bold.woff) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url(./fonts/Quicksand-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Work Sans';
  src: local('Work Sans'), url(./fonts/WorkSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(./fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Inconsolata';
  src: local('Inconsolata'), url(./fonts/Inconsolata-Regular.ttf) format('truetype')
}

body {
  margin: 0;
  overflow-y: scroll;
  font-family: 'Quicksand', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Nexa Bold', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
